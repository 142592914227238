import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default function BiokohleProduktPage() {
  return (
    <Layout className="right-sidebar">
      <SEO title="Produkte: Biokohle" description="Pflanzenkohle ist äußerst porös und besitzt eine riesige Oberfläche. Deshalb kann sie bis zur fünffachen Menge ihres Eigengewichtes an Wasser und darin gelöste Nährstoffe aufnehmen" />

      <div className="wrapper">
        <div className="container" id="main">
          <article id="content">
            <header>
              <h2>Produkte</h2>
              <p>Biokohle - Pflanzenkohle / Futterkohle / Aktivkohle</p>
            </header>

            <h3>PFLANZENKOHLE IM BODEN</h3>
            <p>
              Pflanzenkohle ist äußerst porös und besitzt eine riesige Oberfläche von bis zu 300 Quadratmetern pro Gramm. Deshalb kann sie bis zur fünffachen Menge ihres Eigengewichtes an Wasser und darin gelöste Nährstoffe aufnehmen (Quelle: Scheub/Pieplow/Schmidt, Terra Preta, 2015). Unbehandelte Pflanzenkohle entfaltet erst mal noch keine bodenverbessernde Wirkung. Sie muss zuerst mit Nährstoffen „aufgeladen“ und mit Mikroorganismen besiedelt werden. Die „Aufladung“ der Pflanzenkohle kann mit verschiedenen Verfahren wie beispielsweise der Kompostierung erreicht werden.
            </p>

            <div className="image featured"><Image /></div>

            <p>Positive Effekte:</p>
            <ul>
              <li>Verbesserte Speicherfähigkeit für Wasser und Nährstoffe.</li>
              <li>Geringere Nährstoff- und damit auch Nitrat-Auswaschungen ins Grundwasser </li>
              <li>Stabiles Pflanzenwachstum mit geringeren Ernteausfällen</li>
              <li>Aktivierung des Bodenlebens. Mikroorganismen finden idealen Lebensraum</li>
              <li>Weniger klimaschädliche Emissionen wie zum Beispiel Lachgas oder  Ammoniak.</li>
              <li>Aktiver Klimaschutz: Der in der Kohle gebundene Kohlenstoff wird über Jahrhunderte im Boden gespeichert.</li>
            </ul>

            <h3>PFLANZENKOHLE ALS PFLANZSUBSTRAT</h3>

            <p>
              Bäume und Pflanzen sind für unser Wohlbefinden und Klima unverzichtbar. Sie sind jedoch zunehmend besonderen Stressfaktoren ausgesetzt wie lange Trocken-perioden oder Starkregenfälle. Stadtbäume leiden zudem unter der Boden-versiegelung und Bodenverdichtung im urbanen Raum, die die Verfügbarkeit von Wasser und Sauerstoff für die Baumwurzeln stark eingeschränkt (Quelle: EMBREN, Planting Urban Trees with Biochar, 2016). Das hat zur Folge, dass gerade diese Bäume eine deutlich kürzere Lebensdauer haben, höhere Instandhaltungkosten verursachen und dass ein immer höher werdender Prozentsatz an Neupflanzungen das erste Jahr nicht überlebt. Vielfach wird deshalb versucht, durch synthetisch hergestellte Düngemittel das Wachstum der Bäume zu fördern. Angesichts negativer Folgeerscheinungen wie Treibhausgas-Emissionen, Versauerung, Versalzung und Verluste von Bodenkohlenstoff suchen immer mehr Städte nach alternativen Strategien.
            </p>
            <p>
              Einige Großstädte wie Stockholm (Schweden) oder forstwirtschaftliche Betriebe im Quebec (Kanada) sind deshalb dazu übergegangen, ihre Bäume in Mischsubstrate mit Pflanzenkohle zu pflanzen. Pflanzenkohle ist nicht nur viel poröser als Sand oder Ton, sie wird auch nicht so schnell biologisch abgebaut oder verdichtet wie beispiels-weise Torf. Die hohe Porosität der Pflanzenkohle fördert den Gasaustausch und die Wasserhaltekapazität an den Wurzeln und sorgt insgesamt mit ihrer hohen Durch-lässigkeit für eine verbesserte Wurzeldurchdringung.
            </p>

            <p>Positive Effekte:</p>
            <ul>
              <li>
                Pflanzenkohle verbessert die Wurzelbelüftung, erhöht den pH-Wert und beugt so Wurzelkrankheiten vor.
              </li>
              <li>
                Pflanzenkohle reguliert den Wassergehalt und unterstützt das mikrobielle Leben im Boden, was zu einer erhöhten Pflanzenproduktivität führt.
              </li>
              <li>
                Pflanzenkohle bietet eine hohe Wasserspeicherkapazität und wirkt als strukturierende Komponente im Boden und in Substraten.
              </li>
              <li>
                Darüber hinaus entsteht kein zusätzlicher Aufwand bei der Verwendung von Pflanzenkohle, weder für die Baumschulproduzenten noch für die Wiederaufforstung, da der Einsatz von Pflanzenkohle die gleiche technische Ausrüstung erfordert wie für die Verarbeitung von Torf.
              </li>
            </ul>

            <p>(Quelle: LANGE/ALLAIRE, Substrates containing Biochar for White Spruce Production in Nursery, 2018)</p>

            <h3>FUTTERKOHLE – NATÜRLICH GESUNDE TIERE</h3>

            <p>
              Als besonders geeignet erweisen sich natürliche Karbon-Produkte immer dann, wenn sie mehrfachen Nutzen haben, wie beispielsweise auch als Futterkohle. Diese verbessert als Futtermittelzusatz auf schonende und effektive Weise die Tiergesund-heit und reduziert ausgeschieden die Geruchs- und Ammoniakbildung im Stall.
            </p>

            <p>Positive Effekte:</p>

            <ul>
              <li>
                Pflanzenkohle als Siliermittel verhindert Schimmel und Pilzbildung. Toxine werden adsorbiert, Milchsäurebakterien arbeiten effektiver. Die Stallhygiene verbessert sich.
              </li>
              <li>
                Pflanzenkohle verringert die Einstreufeuchtigkeit, bindet Ammonium und andere Giftstoffe und erhöht die Fußballengesundheit. Die Einstreu bleibt länger scharrfähig.
              </li>
              <li>
                Pflanzenkohle als Futterzusatz (Futterkohle) regelt den Nährstoffhaushalt im Darmtrakt. Bakterien siedeln sich vermehrt an, die Futtereffektivität und die Schlachtgewichte erhöhen sich.
              </li>
              <li>
                Die aufgenommene Futterkohle bindet Toxine im Verdauungssystem. die Tiere bleiben gesund, vital und ausgeglichen. Tierarzt- und Arzneikosten verringern sich.
              </li>
            </ul>

            <h3>AKTIVKOHLE – FÜR INDUSTRIELLE ZWECKE</h3>

            <p>
              Mit dem von uns eingesetzten Anlagensystem haben wir die besten technischen Voraussetzungen dafür geschaffen, damit Aktivkohle gemäß den in Europa geltenden Umweltstandards aus regional nachwachsenden Rohstoffen oder regionalen Biomassereste-Aufkommen hergestellt werden können. Mit einer präzisen und sauberen Karbonisierungstechnologie lassen sich im industriellen Maßstab Spezialkohlen mit Oberflächen von bis zu 1000 m3/g herstellen, die insbesondere als Filtermaterial in der Abwasserbehand-lung und in der Trinkwasseraufbereitung ihren Absatz finden.
            </p>
          </article>
        </div>
      </div>
    </Layout>
  )
}

function Image() {
  const { fungus } = useStaticQuery(graphql`
    query {
      fungus: file(relativePath: { eq: "pawel-chu-636930-unsplash.jpg" }) {
        childImageSharp { fluid(maxWidth: 1200) { ...GatsbyImageSharpFluid } }
      }
    }
  `)

  return <Img fluid={fungus.childImageSharp.fluid} />
}
